export const environment = {
  production                        : false,
  defaultLanguage                   : 'nl-NL',
  defaultTheme                      : 'light--primary',
  businessPerformanceApplicationLink: 'https://www.businessperformanceapplicatie.nl',
  eHerkenningLink                   : 'https://www.eherkenning.nl',
  axiomRealEstateNlLink             : 'https://www.axiom-realestate.eu/nl/mdmb?email=',
  axiomRealEstateEnLink             : 'https://www.axiom-realestate.eu/en/mdmb?email=',
  sbrNexusSupportLink               : 'https://support.mijndatamijnbusiness.nl',
  eHerkenningSuppliersLink          : 'https://support.sbrnexus.nl/hc/nl/articles/360016587019-Leveranciers',
  customApiKeyRequestLink           : 'https://forms.office.com/Pages/ResponsePage.aspx?id=a-BhIjpwGUSTuxzjqUWZFR2y8HrWz2BOuHIWAgPnR3pUNUNXVUMyME1aOEtOMUg0SFdOQk85MEZZRS4u',
  maxSizeImageUpload                : 1,
  kycPlatformLink                   : 'https://novipub.dev.skarp.nl/xbrl-nexus'
};
